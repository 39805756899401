var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-container" }, [
    _c("div", { staticClass: "physical-card-container" }, [
      _c("div", { staticClass: "flex items-center justify-end" }, [
        _c(
          "div",
          {
            staticClass: "flex items-center cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.setCardType("PHYSICAL")
              },
            },
          },
          [
            _c(
              "svg",
              {
                staticClass: "mr-2",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "14",
                  height: "14",
                  fill: "none",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M13 8H8v5c0 .55-.45 1-1 1s-1-.45-1-1V8H1c-.55 0-1-.45-1-1s.45-1 1-1h5V1c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1Z",
                    fill: "#6956C8",
                  },
                }),
              ]
            ),
            _c("p", { staticClass: "text-brandPurple" }, [_vm._v("Request")]),
          ]
        ),
      ]),
      _vm.loading
        ? _c(
            "div",
            { key: "card" },
            _vm._l(2, function (i) {
              return _c(
                "Card",
                { key: i, staticClass: "mt-4" },
                [
                  _c("Skeleton", { attrs: { height: "1rem", width: "40%" } }),
                  _c(
                    "div",
                    { staticClass: "flex justify-between" },
                    [
                      _c("Skeleton", {
                        staticClass: "mt-3",
                        attrs: { height: "1rem", width: "30%" },
                      }),
                      _c("Skeleton", {
                        staticClass: "float-right mt-3",
                        attrs: { height: "1rem", width: "20%" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("div", [
            _vm.virtualCards.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.virtualCards, function (card) {
                    return _c("Card", { key: card.id, staticClass: "mt-4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between cursor-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.$router.push(
                                "/drawer/card/view-card/" + card.id
                              )
                            },
                          },
                        },
                        [
                          _c("div", [
                            _c("p", { staticClass: "text-sm" }, [
                              _vm._v(_vm._s(card.pan)),
                            ]),
                            _c("p", { staticClass: "text-xs text-blue" }, [
                              _vm._v("Status: " + _vm._s(card.status)),
                            ]),
                          ]),
                          card.status !== "ACTIVE"
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "font-semibold text-sm text-brandPurple cursor-pointer",
                                },
                                [_vm._v(" Activate ")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ])
                  }),
                  1
                )
              : _c("div", [
                  _c(
                    "p",
                    { staticClass: "text-grey font-normal text-center py-6" },
                    [_vm._v(" You currently have no debit cards requested ")]
                  ),
                ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }