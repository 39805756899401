var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _vm.currentTab === "Credit cards" ? _c("CreditCards") : _vm._e(),
      _vm.currentTab === "Debit cards" ? _c("DebitCards") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }