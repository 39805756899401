<template>
  <div class="card-container">
    <div class="physical-card-container">
      <div class="flex items-center justify-end">
        <div
          @click="setCardType('PHYSICAL')"
          class="flex items-center cursor-pointer"
        >
          <svg
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
          >
            <path
              d="M13 8H8v5c0 .55-.45 1-1 1s-1-.45-1-1V8H1c-.55 0-1-.45-1-1s.45-1 1-1h5V1c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1Z"
              fill="#6956C8"
            />
          </svg>

          <p class="text-brandPurple">Request</p>
        </div>
      </div>
      <!-- <transition name="fade" mode="out-in"> -->
      <div key="card" v-if="loading">
        <Card v-for="i in 2" :key="i" class="mt-4">
          <Skeleton height="1rem" width="40%" />
          <div class="flex justify-between">
            <Skeleton height="1rem" width="30%" class="mt-3" />
            <Skeleton height="1rem" width="20%" class="float-right mt-3" />
          </div>
        </Card>
      </div>
      <div v-else>
        <div v-if="virtualCards.length > 0">
          <Card class="mt-4" v-for="card in virtualCards" :key="card.id">
            <div
              class="flex items-center justify-between cursor-pointer"
              @click="$router.push(`/drawer/card/view-card/${card.id}`)"
            >
              <div>
                <p class="text-sm">{{ card.pan }}</p>
                <p class="text-xs text-blue">Status: {{ card.status }}</p>
              </div>
              <p
                v-if="card.status !== 'ACTIVE'"
                class="font-semibold text-sm text-brandPurple cursor-pointer"
              >
                Activate
              </p>
            </div>
          </Card>
        </div>
        <div v-else>
          <p class="text-grey font-normal text-center py-6">
            You currently have no debit cards requested
          </p>
        </div>
      </div>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { BackNav } from "@/UI/Navigation";
export default {
  components: {
    BackNav,
  },
  data: () => ({
    params: {
      pageSize: 20,
      page: 0,
      sortOrder: "ASC",
      from: "",
      to: "",
    },
    paginateLoading: false,
    loading: false,
    handleMore: false,
    physicalCards: [],
    virtualCards: [],
  }),
  computed: {
    ...mapState({
      cards: (state) => state?.card?.cards,
    }),
  },
  mounted() {
    this.resetCards();
    this.getCards();
  },
  methods: {
    ...mapActions("card", ["getAllCards", "resetCards", "updateCardType"]),
    getCards(type) {
      // if it is pagination type show loading for pagination else general loading
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.loading = true);
      this.getAllCards({
        params: this.params,
        type: type,
      })
        .then((res) => {
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
          this.filterByCardType();
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        });
    },
    filterByCardType() {
      this.cards.forEach((item) => {
        if (item.type === "VIRTUAL") {
          this.virtualCards.push(item);
        } else {
          this.physicalCards.push(item);
        }
      });
    },
    setCardType(type) {
      // this.updateCardType(type);
      this.$router.push("/drawer/card/request-card");
    },
  },
};
</script>

<style scoped>
.card-container {
  max-width: 36.563rem;
  margin: auto;
}
</style>
