<template>
  <div class="card-container">
    <BackNav @click="$router.push('/drawer')" />
    <!-- <div class="physical-card-container">
      <div class="flex items-center justify-between">
        <p class="text-grey text-sm">PHYSICAL CARDS</p>
        <div
          @click="setCardType('PHYSICAL')"
          class="flex items-center cursor-pointer"
        >
          <svg
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
          >
            <path
              d="M13 8H8v5c0 .55-.45 1-1 1s-1-.45-1-1V8H1c-.55 0-1-.45-1-1s.45-1 1-1h5V1c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1Z"
              fill="#6956C8"
            />
          </svg>

          <p class="text-brandPurple">Request</p>
        </div>
      </div>
      <transition name="fade" mode="out-in">
      <div key="card" v-if="loading">
        <Card v-for="i in 2" :key="i" class="mt-4">
          <Skeleton height="1rem" width="40%" />
          <div class="flex justify-between">
            <Skeleton height="1rem" width="30%" class="mt-3" />
            <Skeleton height="1rem" width="20%" class="float-right mt-3" />
          </div>
        </Card>
      </div>
      <div v-else>
        <div v-if="physicalCards.length > 0">
          <Card class="mt-4" v-for="card in physicalCards" :key="card.id">
            <div
              class="flex items-center justify-between cursor-pointer"
              @click="$router.push(`/drawer/card/view-card/${card.id}`)"
            >
              <div>
                <p class="text-sm">{{ card.pan }}</p>
                <p class="text-xs text-blue">
                  Status:
                  <span>{{ card.status.toLowerCase() | capitalize }}</span>
                </p>
              </div>
              <p
                v-if="card.status !== 'ACTIVE'"
                class="font-semibold text-sm text-brandPurple cursor-pointer"
              >
                Activate
              </p>
            </div>
          </Card>
        </div>
        <div v-else>
          <p class="text-grey font-normal text-center py-6">
            You currently have no physical cards requested
          </p>
        </div>
      </div>
      </transition>
    </div> -->
    <div class="virtual-card-container mt-6">
      <div class="flex items-center justify-between">
        <p class="text-grey text-sm">VIRTUAL CARDS</p>
        <div
          @click="setCardType('VIRTUAL')"
          class="flex items-center cursor-pointer"
        >
          <svg
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
          >
            <path
              d="M13 8H8v5c0 .55-.45 1-1 1s-1-.45-1-1V8H1c-.55 0-1-.45-1-1s.45-1 1-1h5V1c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1Z"
              fill="#6956C8"
            />
          </svg>

          <p class="text-brandPurple">Request</p>
        </div>
      </div>
      <div key="card" v-if="loading">
        <Card v-for="i in 2" :key="i" class="mt-4">
          <Skeleton height="1rem" width="40%" />
          <div class="flex justify-between">
            <Skeleton height="1rem" width="30%" class="mt-3" />
            <Skeleton height="1rem" width="20%" class="float-right mt-3" />
          </div>
        </Card>
      </div>
      <div v-else>
        <div v-if="virtualCards.length > 0">
          <Card v-for="card in virtualCards" :key="card.id" class="mt-4">
            <div
              class="flex items-center justify-between cursor-pointer"
              @click="$router.push(`/drawer/card/view-card/${card.id}`)"
            >
              <div>
                <p class="text-sm">{{ card.pan.replace(/^(.{4})(.{6})(.*)$/, "$1 $2 $3") }}</p>
                <p class="text-xs">
                  <span :class="[card.status.toLowerCase()]">
                    {{ card.status.toLowerCase() | capitalize }}</span
                  >
                </p>
              </div>
            </div>
          </Card>
        </div>
        <div v-else>
          <p class="text-grey font-normal text-center py-6">
            You currently have no virtual cards
          </p>
        </div>
      </div>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { BackNav } from "@/UI/Navigation";
export default {
  components: {
    BackNav,
  },
  data: () => ({
    params: {
      pageSize: 20,
      page: 0,
      sortOrder: "DESC",
      from: "",
      to: "",
      type: "VIRTUAL",
    },
    paginateLoading: false,
    loading: false,
    handleMore: false,
    physicalCards: [],
  }),
  computed: {
    ...mapState({
      virtualCards: (state) => state?.card?.virtualCards,
    }),
  },
  mounted() {
    this.resetCards();
    this.getVirtualCards();
  },
  methods: {
    ...mapActions("card", [
      "getAllVirtualCards",
      "resetCards",
      "updateCardType",
    ]),
    getVirtualCards(type) {
      // if it is pagination type show loading for pagination else general loading
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.loading = true);
      this.getAllVirtualCards({
        params: this.params,
        type: type,
      })
        .then((res) => {
          // Go straight to request page if user has no cards
          if (res.data.length === 0) {
            this.$router.push("/drawer/card/request-card");
          } else if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        });
    },

    setCardType() {
      this.$router.push("/drawer/card/request-card");
    }
  },
};
</script>

<style scoped>
.card-container {
  max-width: 36.563rem;
  margin: auto;
}
</style>
