<template>
  <div class="card-container">
    <!-- <Tabs :options="tabOptions" v-model="currentTab" class="mb-10" /> -->
    <CreditCards v-if="currentTab === 'Credit cards'" />
    <DebitCards v-if="currentTab === 'Debit cards'" />
  </div>
</template>

<script>
import CreditCards from "./CreditCards.vue";
import DebitCards from "./DebitCards.vue";
import Tabs from "@/UI/Tab/Tab";
export default {
  components: {
    CreditCards,
    DebitCards,
    Tabs,
  },
  data() {
    return {
      currentTab: "Credit cards",
      tabOptions: ["Credit cards", "Debit cards"],
    };
  },
};
</script>

<style scoped>
.card-container {
  max-width: 36.563rem;
  margin: auto;
}
</style>